.primary-btn {
  min-width: 135px;
  font-size: 1rem;
  font-family: $font-regular;
  border-radius: 8px;

  color: $color-white !important;
  background-color: $color-black !important;
  &.lg {
    width: 100%;
  }
  &.round {
    border-radius: 20px;
  }
  &.clr-transp {
    background-color: transparent !important;
    color: $color-black !important;
    border: 1px solid color-black;
  }
  &.clr-blue {
    background-color: #3559fc !important;
    color: #fff !important;
  }
  &:focus,
  &:hover {
    box-shadow: 1px 1px 10px 3px #bfbfbf;
  }
}

.input-wrapper {
  margin-bottom: 1.25rem;
  position: relative;
  font-family: $font-regular;
  .eye-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    cursor: pointer;
    color: $color-black;
  }
  label {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }
  span {
    font-family: $font-extra-light;
    position: absolute;
    color: red;
    font-size: 0.75rem;
  }
  option {
    font-size: 0.75rem;
  }
}

.text-input {
  padding: 0.5rem;
  border: 1px solid $color-mid-gray;
  width: 100%;
  border-radius: 6px;
  font-size: 0.75rem;
  font-family: $font-regular;
  box-shadow: none !important;
  &:focus {
    border-color: $color-black;
  }
  &.error {
    border: 1px solid red !important;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.loader {
  display: flex;
  font-size: 1rem;
  text-align: center;
  color: #19233c;
  position: absolute;
  top: 45%;
  left: 50%;
}

.dotOne {
  animation: visibilityOne 2s linear infinite;
}
@keyframes visibilityOne {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dotTwo {
  animation: visibilityTwo 2s linear infinite;
}
@keyframes visibilityTwo {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dotThree {
  animation: visibilityThree 2s linear infinite;
}
@keyframes visibilityThree {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.otp-field-container {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: $font-bold;
    font-size: 2rem;
  }
}

.otp-field {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border-color: $color-black !important;
  box-shadow: none !important;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: inherit;
}
.loader-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius: inherit;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.loader-svg {
  font-size: 2rem;
  color: #37e637;
  animation: color-fill 1s ease-in-out;
}

@keyframes color-fill {
  0% {
    fill: transparent;
  }
  100% {
    fill: #37e637;
  }
}

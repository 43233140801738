.dash-wrapper {
  height: inherit;
}

.dash-content {
  display: flex;
  width: 100%;
  height: calc(100% - 80px);
}

.sidebar-wrapper {
  min-width: 18%;
  box-shadow: 5px 0px 10px -1px rgba(0, 0, 0, 0.25);
  padding: 25px 0;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
}

.sidebar-item {
  border-bottom: 1px solid $color-gray;
  padding-left: 20px;
  font-size: 0.75rem;
  font-family: $font-regular;
  span {
    cursor: pointer;
    &:hover {
      color: rgb(53, 89, 252);
    }
  }
}

.main-wrapper {
  flex-grow: 1;
  padding: 25px;
}

.main-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.app-modal {
  max-width: 560px;
  .app-modal-title {
    font-size: 1rem;
    margin-bottom: 10px;
    font-family: $font-bold;
  }
  .modal-content {
    padding: 30px 20px;
  }
}

.app-modal-cta {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin-top: 20px;
}

.app-modal-desc {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-family: $font-light;
  flex-direction: column;
  margin-block: 10px;
  .desc {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  svg {
    font-size: 20px;
    cursor: pointer;
  }
  .label {
    width: 75px;
  }
}

.modal-secret {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 5px;
  padding: 8px;
  font-size: 0.75rem;
  font-family: $font-regular;
  // margin-left: 10px;
  background-color: rgba(128, 128, 128, 0.15);
}

.warning {
  color: #ffcc00 !important;
  font-weight: bold;
}

.modal-tooltip {
  .tooltip-inner {
    font-size: 12px;
  }
}
.modal-note {
  font-size: 0.75rem;
  font-family: $font-regular;
  margin-top: 10px;
  color: #d8ca25;
  svg {
    font-size: 20px;
  }
}

.select-input {
  border: 1px solid transparent;
  width: 100%;
  border-radius: 6px;
  font-size: 0.75rem;
  font-family: $font-regular, sans-serif;
  background-color: #eff2f5;
  box-shadow: none !important;

  &.error {
    border: 1px solid red !important;
  }
}

.app-card {
  width: 250px;
  border-radius: 10px;
  height: 250px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px 5px #cdd8ed80;
  align-items: center;
  line-height: 1rem;
  padding-block: 10px;
  cursor: pointer;
  .app-card-img {
    width: 100%;
    border-bottom: 1px solid #cdd8ed80;
    display: flex;
    justify-content: center;
    img {
      width: 144px;
      height: 144px;
    }
  }
  .app-desc {
    width: 100%;
    padding: 10px;
    font-size: 0.75rem;
  }
  .app-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .app-chain {
    color: grey;
  }
}

@import "variables.scss";
@import "typography.scss";
@import "auth.scss";
@import "common.scss";
@import "dashboard.scss";
@import "home.scss";

html {
  font-size: 18px;
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: $font-regular, -apple-system, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 1.25rem;
  font-family: $font-bold;
  margin: 0;
  line-height: 1;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.main-header {
  height: 80px;
  line-height: 1.2;
  padding: 20px;
  font-size: 0.75rem;
  color: grey;
  box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font-regular;
  span {
    color: #000;
    font-size: 1rem;
    font-family: $font-bold;
  }
  svg {
    color: grey;
    font-size: 24px;
    margin-right: 20px;
    cursor: pointer;
  }
}

.empty-state {
  min-width: 40%;
  align-self: center;
  margin-inline: auto;
  height: 50%;
  background-color: rgba(53, 89, 252, 0.1);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 25px;
  .empty-state-title {
    font-size: 1.25rem;
  }
  .empty-state-sub {
    font-size: 1rem;
    span {
      color: $color-blue;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        color: $color-black;
      }
    }
  }
}

.not-found {
  font-family: $font-bold;
  text-align: center;
  b {
    font-size: 3rem;
    color: red;
  }
  a {
    float: left;
    padding-top: 10px;
    font-size: 0.7rem;
    padding-left: 10px;
  }
}
